import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useSwipeable } from 'react-swipeable';
import Slider from 'react-slick';

import 'swiper/swiper.min.css';
import Memory from 'components/tribut/MemorySection/Memory/Memory';
import Loading from 'components/UI/Loading/Loading';

import { getMemoryList } from 'store/memory-actions';

import classes from './MemoriesCarousel.module.css';

const MemoriesCarousel = props => {
  const dispatch = useDispatch();
  const handlers = useSwipeable({
    onSwipedLeft: () => nextMemoryHandler(),
    onSwipedRight: () => prevMemoryHandler()
  });
  let sliderRef = useRef(null);
  const [memoryCarousel, setMemoryCarousel] = useState(
    props.memoryView
  );
  const [fullImage, setFullImage] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [newMemories, setNewMemories] = useState([]);
  const [newMemoriesCarousel, setNewMemoriesCarousel] = useState(
    props.memories
  );
  const [changeMemory, setChangeMemory] = useState(0);
  const [loading, setLoading] = useState(true);
  const [initialMemory, setInitialMemory] = useState(0);
  const [initialMemoryCarousel, setInitialMemoryCarousel] =
    useState(0);

  useEffect(() => {
    let memoriesData = [];
    let start = 0;
    let indexMemory = 0;
    props.memories.map((memory, index) => {
      if (props.memoryView === memory.memoryId) {
        indexMemory = index - 5;
      }
    });
    props.memories.map(() => {
      if (indexMemory < 0) {
        memoriesData.push(
          props.memories[props.memories.length + indexMemory]
        );
        indexMemory = indexMemory + 1;
      }
    });

    props.memories.map((memory, index) => {
      if (indexMemory >= 0) {
        if (indexMemory === index && start < 10) {
          memoriesData.push(memory);
          indexMemory = index + 1;
          start = start + 1;
        }
      }
    });
    if (indexMemory === props.memories.length) {
      for (let index = 0; memoriesData.length <= 10; index++) {
        memoriesData.push(props.memories[index]);
      }
    }
    setNewMemories(memoriesData);
  }, []);

  const prevMemoryHandler = () => {
    setInitialMemory(initialMemory - 1);
    previous();
  };

  const nextMemoryHandler = () => {
    setInitialMemory(initialMemory + 1);
    next();
  };

  const fullImageHandler = () => {
    setFullImage(!fullImage);
  };

  useEffect(() => {
    if (initialMemory === 8) {
      loadMoreMemoriesHandler(8);
    }
    if (initialMemory === 1) {
      loadMoreMemoriesHandler(1);
    }
  }, [initialMemory]);

  useEffect(() => {
    if (newMemories.length > 0) {
      if (isInitial === true) {
        newMemories.map((memory, index) => {
          if (props.memoryView === memory.memoryId) {
            setInitialMemory(index);
          }
        });
        props.memories.map((memory, index) => {
          if (props.memoryView === memory.memoryId) {
            setInitialMemoryCarousel(index);
          }
        });
        newMemories;
        setIsInitial(false);
        setTimeout(() => {
          setLoading(false);
        }, 10);
      }
    }
  }, [newMemories]);

  useEffect(() => {
    if (changeMemory > 0) {
      let memoriesData = [];
      let start = 0;
      let indexMemory = 0;
      props.memories.map((memory, index) => {
        if (changeMemory === memory.memoryId) {
          indexMemory = index - 5;
          if (indexMemory <= 0) {
            indexMemory = 0;
          }
        }
      });
      props.memories.map((memory, index) => {
        if (indexMemory === index && start < 10) {
          memoriesData.push(memory);
          indexMemory = index + 1;
          start = start + 1;
        }
      });
      memoriesData.map((memory, index) => {
        if (changeMemory === memory.memoryId) {
          setInitialMemory(index);
        }
      });
      setNewMemories(memoriesData);
    }
  }, [changeMemory]);

  const next = () => {
    sliderRef.slickNext();
    newMemories.map((memory, index) => {
      if (initialMemory + 1 === index) {
        history.pushState(null, null, memory.memoryId);
        setMemoryCarousel(memory.memoryId);
      }
    });
  };

  const previous = () => {
    sliderRef.slickPrev();
    let newMemorySelected = 0;
    props.memories.map((memory, index) => {
      if (memoryCarousel === memory.memoryId) {
        newMemorySelected = index - 1;
      }
    });
    if (newMemorySelected < 0) {
      newMemorySelected = props.memories.length - 1;
    }
    props.memories.map((memory, index) => {
      if (newMemorySelected === index) {
        setMemoryCarousel(memory.memoryId);
        history.pushState(null, null, memory.memoryId);
      }
    });
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    lazyLoad: true,
    initialSlide: initialMemoryCarousel - 2,
    arrows: false
  };

  const loadMoreMemoriesHandler = indexMemory => {
    let memoryId = 0;
    if (indexMemory === 8 || indexMemory === 1) {
      newMemories.map((newMemory, index) => {
        if (indexMemory === index) {
          memoryId = newMemory.memoryId;
          let memoriesData = [];
          let start = 0;
          let indexMemory = 0;
          props.memories.map((memory, index) => {
            if (memoryId === memory.memoryId) {
              indexMemory = index - 5;
            }
          });
          props.memories.map(() => {
            if (indexMemory < 0) {
              memoriesData.push(
                props.memories[props.memories.length + indexMemory]
              );
              indexMemory = indexMemory + 1;
            }
          });
          props.memories.map((memory, index) => {
            if (indexMemory >= 0) {
              if (indexMemory === index && start < 10) {
                memoriesData.push(memory);
                indexMemory = index + 1;
                start = start + 1;
              }
            }
          });
          if (indexMemory === props.memories.length) {
            for (let index = 0; memoriesData.length <= 10; index++) {
              memoriesData.push(props.memories[index]);
            }
          }
          memoriesData.map((memory, index) => {
            if (memoryId === memory.memoryId) {
              setInitialMemory(index);
            }
          });
          setNewMemories(memoriesData);
        }
      });
    }
  };

  const showIdHandler = idMemory => () => {
    setChangeMemory(idMemory);
    setMemoryCarousel(idMemory);
  };

  const deleteMemoryHandler = idMemory => {
    // nextMemoryHandler();
    const memoriesData = newMemories;
    let newMemoriesData = [];
    newMemoriesData = memoriesData.filter(
      memory => memory.memoryId != idMemory
    );
    setNewMemories(newMemoriesData);
    let newMemoriesDataCarousel = [];
    newMemoriesDataCarousel = newMemoriesCarousel.filter(
      memory => memory.memoryId != idMemory
    );
    setNewMemoriesCarousel(newMemoriesDataCarousel);
    newMemories.map((memory, index) => {
      if (initialMemory + 1 === index) {
        setMemoryCarousel(memory.memoryId);
      }
    });
    dispatch(getMemoryList(props.tributId));
  };

  return (
    <>
      {!loading ? (
        <div className={classes.memoriesContainer}>
          <>
            {newMemories.map((memory, index) => (
              <div
                className={
                  index === initialMemory
                    ? classes.memoryContainer
                    : classes.memoryContainerNoShow
                }
                id={`memory-${memory.memoryId}`}
                key={memory.memoryId}
              >
                <Memory
                  dataMemory={memory}
                  tributId={props.tributId}
                  memoryView={props.memoryView}
                  prevMemoryHandler={prevMemoryHandler}
                  nextMemoryHandler={nextMemoryHandler}
                  deleteMemoryHandler={deleteMemoryHandler}
                  fullImageHandler={fullImageHandler}
                  fullImage={fullImage}
                />
              </div>
            ))}
          </>
          <div className={classes.allMemoriesCarouselContainer}>
            <div {...handlers}>
              <Slider
                {...settings}
                ref={slider => {
                  sliderRef = slider;
                }}
              >
                {newMemoriesCarousel.map(memory => (
                  <figure
                    onClick={showIdHandler(memory.memoryId)}
                    key={memory.memoryId}
                    className={
                      memoryCarousel === memory.memoryId
                        ? classes.memorySelected
                        : classes.memorySelect
                    }
                  >
                    <img
                      src={memory.thumbnail}
                      alt={memory.mediaType}
                      className={classes.img}
                    />
                  </figure>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

MemoriesCarousel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  initialMemory: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  newMemories: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoryView: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  loadMoreMemoriesHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memories: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  style: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func
};

export default MemoriesCarousel;
